import React, { useState, useEffect, useContext } from "react";
import {
  collection,
  onSnapshot,
  query,
  doc,
  getDoc,
  updateDoc,
  setDoc,
  addDoc,
} from "firebase/firestore";
import { DataGrid } from "@mui/x-data-grid";
import { auth, firestore } from "../firebase";
import {
  Select,
  MenuItem,
  Button,
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Modal,
  Divider,
} from "@mui/material";
import PropertyContext from "../contexts/PropertyContext";

const WOModal = ({ open, onClose, rooms }) => {
  const [selectedRoom, setSelectedRoom] = useState(0);
  const [approvedPTE, setApprovedPTE] = useState(false);
  const [desc, setDesc] = useState();

  const { selectedProperty } = useContext(PropertyContext);

  const pteList = [
    { id: 1, option: "YES", value: true },
    { id: 2, option: "NO", value: false },
  ];
  const workOrdersRef = collection(
    firestore,
    "properties",
    selectedProperty,
    "workOrders"
  );

  const handleClose = async () => {
    if (selectedRoom === 0) {
      //should give toaster notification or something to verify but for now we will just close
      onClose();
    } else {
      const workOrderData = {
        completed: false,
        isSignedFor: false,
        markedCompleteBy: null,
        markedCompleted: false,
        markedCompletedTime: null,
        room: selectedRoom,
        pteApproved: approvedPTE,
        orderDetails: desc,
        status: "pending",
        phoneNumber: null,
        receiveUpdates: false,
        reportedTime: new Date(),
      };
      await addDoc(workOrdersRef, workOrderData);

      onClose();
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyle}>
        <Box width="100%">
          <Box display="flex" sx={{ p: 0.5 }} justifyContent="center">
            <Typography>New Work Order</Typography>
            <Divider />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            width="100%"
            justifyContent="space-around"
            padding="5px"
          >
            <Box sx={{ p: 0.5 }}>
              <Select
                labelId="room-select-label"
                id="room-select"
                label="Room #"
                value={selectedRoom}
                onChange={(event) => {
                  setSelectedRoom(event.target.value);
                }}
              >
                <MenuItem key={0} value={0}>
                  Room #
                </MenuItem>
                {rooms.map((option) => (
                  <MenuItem key={option.id} value={option.displayName}>
                    {option.displayName}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box sx={{ p: 0.5 }}>
              <Select
                labelId="PTE-select-label"
                id="PTE-select"
                label="PTE"
                value={approvedPTE}
                onChange={(event) => {
                  setApprovedPTE(event.target.value);
                }}
              >
                {pteList.map((option) => (
                  <MenuItem key={option.id} value={option.value}>
                    {option.option}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
        </Box>
        <Box sx={{ p: 0.5 }}>
          <TextField
            id="outlined-multiline-static"
            label="Description"
            fullWidth
            multiline
            rows={4}
            defaultValue=""
            onChange={(event) => {
              setDesc(event.target.value);
            }}
          />
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Button onClick={() => onClose()} sx={{ mt: 2 }}>
              Cancel
            </Button>
          </Box>
          <Box>
            <Button onClick={handleClose} sx={{ mt: 2 }}>
              Generate Work Order
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

const WorkOrders = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [rooms, setRooms] = useState([]);
  const [openWorkOrders, setOpenWorkOrders] = useState([]);
  const [workOrdersPendingApproval, setWorkOrdersPendingApproval] = useState(
    []
  );
  const [employees, setEmployees] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const { selectedProperty } = useContext(PropertyContext);

  useEffect(() => {
    setIsLoading(true);
    const roomsRef = collection(
      firestore,
      "properties",
      selectedProperty,
      "units"
    );
    const workOrdersRef = collection(
      firestore,
      "properties",
      selectedProperty,
      "workOrders"
    );
    const employeesRef = collection(
      firestore,
      "properties",
      selectedProperty,
      "Staff"
    );
    const q = query(roomsRef);
    const q2 = query(workOrdersRef);
    const q3 = query(employeesRef);

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const roomsArray = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRooms(roomsArray);

      const unsub = onSnapshot(q2, (snap) => {
        const openOrders = snap.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          .filter((request) => request.completed === false)
          .filter((request) => request.markedCompleted !== true);

        const pendingOrders = snap.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          .filter((request) => request.completed === false)
          .filter((request) => request.markedCompleted === true);

        setOpenWorkOrders(openOrders);
        setWorkOrdersPendingApproval(pendingOrders);

        const uns = onSnapshot(q3, (sna) => {
          const employeeList = sna.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setEmployees(employeeList);
          console.log(employeeList);
          setIsLoading(false);
        });
      });
    });

    return unsubscribe; // Unsubscribe from the listener when the component unmounts
  }, []);

  const markAsCompleted = async (orderId, uuid) => {
    console.log(`marked as completed: `, orderId, uuid);

    const orderRef = doc(
      firestore,
      "properties",
      selectedProperty,
      "workOrders",
      orderId
    );
    const userRef = doc(
      firestore,
      "properties",
      selectedProperty,
      "Staff",
      uuid
    );
    //close out order
    const updatedWODetails = {
      completed: true,
    };
    await updateDoc(orderRef, updatedWODetails);

    //add +1 to user's work orders
    const currentWOs = Number(
      employees.find((member) => member.id === uuid).workOrders
    );
    const updatedUserProfileData = {
      turns: currentWOs + 1,
    };
    await updateDoc(userRef, updatedUserProfileData);
  };

  const handleModalOpen = () => setModalOpen(!modalOpen);

  const columns = [
    { field: "room", headerName: "Room #", width: 70, editable: false },
    {
      field: "orderDetails",
      headerName: "Details",
      width: 250,
      editable: false,
      flex: 1,
    },
    {
      field: "markedCompleteBy",
      headerName: "Completed By",
      width: 130,
      editable: false,
      renderCell: (params) => (
        <Typography>
          {employees.find((member) => member.id === params.value).name}
        </Typography>
      ),
    },
    {
      field: "markedCompletedTime",
      headerName: "Completed Time",
      width: 130,
      editable: false,
      renderCell: (params) => (
        <Typography>{new Date(params.value).toLocaleTimeString()}</Typography>
      ),
    },
    {
      field: "completed",
      headerName: "Mark Complete",
      width: 130,
      editable: true,
      renderCell: (params) => (
        <Button
          onClick={() =>
            markAsCompleted(params.id, params.row.markedCompleteBy)
          }
        >
          Approve
        </Button>
      ),
    },
  ];
  const columnsOpen = [
    { field: "room", headerName: "Room #", width: 70, editable: false },
    {
      field: "orderDetails",
      headerName: "Details",
      width: 250,
      editable: false,
      flex: 1,
    },
    { field: "pteApproved", headerName: "PTE", width: 70, editable: false },
  ];

  return (
    <Box p={2}>
      {isLoading ? (
        <React.Fragment>
          <Typography>Im Loading!</Typography>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box display="flex" width="100%" justifyContent="flex-end">
            <Box>
              <Button variant="contained" onClick={handleModalOpen}>
                New Work Order
              </Button>
            </Box>
          </Box>
          <Box>
            <WOModal open={modalOpen} onClose={handleModalOpen} rooms={rooms} />
          </Box>
          {workOrdersPendingApproval.length > 0 && (
            <React.Fragment>
              <Typography>Completed Work Orders Pending Approval</Typography>
              <DataGrid
                rows={workOrdersPendingApproval}
                // getRowId={(row) => row.roomId}
                columns={columns}
                // onCellEditCommit={}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
                disableSelectionOnClick
                sx={{ mb: 4 }}
              />
            </React.Fragment>
          )}
          {openWorkOrders.length > 0 && (
            <React.Fragment>
              <Typography>Open Work Orders</Typography>
              <DataGrid
                rows={openWorkOrders}
                // getRowId={(row) => row.roomId}
                columns={columnsOpen}
                // onCellEditCommit={}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
                disableSelectionOnClick
              />
            </React.Fragment>
          )}
          {/* 
                    //button to add a new work order at top right of screen
                    //grid box with work orders pending approval
                    //grid box with open work orders
                    */}
        </React.Fragment>
      )}
    </Box>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default WorkOrders;
