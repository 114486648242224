import React, { useState, useEffect } from "react";
import {
  collection,
  onSnapshot,
  query,
  doc,
  getDoc,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { auth, firestore } from "../firebase";
import {
  Select,
  MenuItem,
  Button,
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Alert,
} from "@mui/material";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";

const AddUser = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [properties, setProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [userType, setUserType] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const functions = getFunctions();
  const createNewUser = httpsCallable(functions, "createNewUser");

  const userTypes = ["maintenance", "housekeeping", "manager", "frontDesk"];

  const createUser = async () => {
    // const createPropertyAccount = async (uid) => {
    //   switch (userType) {
    //     case "maintenance":
    //       await setDoc(doc(propertyRef, uid), userPropertyMaintInfo);
    //       break;
    //     case "housekeeping":
    //       await setDoc(doc(propertyRef, uid), userPropertyHkInfo);
    //       break;
    //     case "frontDesk":
    //       await setDoc(doc(propertyRef, uid), userPropertyFrontDeskInfo);
    //       break;
    //     default:
    //       console.error("user was not correctly created");
    //       break;
    //   }
    // };

    const newUserData = {
      assigned: true,
      expoPushToken: null,
      fullName: `${firstName} ${lastName}`,
      notifications: false,
      properties: [
        {
          displayName: "Siegel Select New Orleans",
          id: "TSG-SELNEW",
        },
      ],
      property: "TSG-SELNEW",
      userName: username,
      userType: userType,
      username: username,
      personalEmail: email,
      password: password,
    };
    const accountCreation = await createNewUser(newUserData);
    console.log(accountCreation);
    console.log(selectedProperty);

    // createUserWithEmailAndPassword(auth, createEmail, createPassword).then(
    //   (res) => {
    //     createUserAccount(res.user.uid);
    //   }
    // );
    // console.log(`account info: `, userAccountMaintInfo)
    // console.log(`property info: `, userPropertyMaintInfo)
    // console.log(createEmail)
    // Create user object with fullName, displayName, email, property, position(userType), and turns set to 0
    // Here, you'll need to create an object and add it to Firestore's users collection

    // Once user has been created get user's id and add object to /properties/{property}/Staff collection
    if (accountCreation.data.success) {
      console.log("User created successfully");
      Alert("User created successfully");
    }
  };

  useEffect(() => {
    setIsLoading(true);
    // Get list of all properties and add to properties state
    // This might involve fetching data from a Firestore collection
    const propertiesRef = collection(firestore, "properties");
    const q = query(propertiesRef);

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const propertiesArray = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      //console.log(propertiesArray)
      setProperties(propertiesArray);
      setIsLoading(false);
      properties.map((property) => {
        console.log(property.id);
        console.log(property);
      });
    });

    return unsubscribe; // Unsubscribe from the listener when the component unmounts
  }, []);

  return (
    <Box p={2}>
      {isLoading ? (
        <React.Fragment>
          <Typography>Im Loading!</Typography>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <FormControl fullWidth margin="normal">
            <InputLabel id="property-select-label">Property</InputLabel>
            <Select
              labelId="property-select-label"
              value={selectedProperty}
              label="Property"
              onChange={(e) => setSelectedProperty(e.target.value)}
            >
              {properties.map((property) => (
                <MenuItem key={property.id} value={property.id}>
                  {property.id}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel id="user-type-select-label">User Type</InputLabel>
            <Select
              labelId="user-type-select-label"
              value={userType}
              label="User Type"
              onChange={(e) => setUserType(e.target.value)}
            >
              {userTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            fullWidth
            margin="normal"
            label="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />

          <TextField
            fullWidth
            margin="normal"
            label="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />

          <TextField
            fullWidth
            margin="normal"
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <TextField
            fullWidth
            margin="normal"
            label="userName"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />

          <TextField
            fullWidth
            margin="normal"
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />

          <Button
            variant="contained"
            color="primary"
            onClick={createUser}
            sx={{ mt: 2 }}
          >
            Submit
          </Button>
        </React.Fragment>
      )}
    </Box>
  );
};

export default AddUser;
